module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Maple Sealing","short_name":"Maple","description":"当社では富士・富士宮市を中心に、シーリング工事と各種防水工事を承っております。雨漏りのお悩みなどお気軽にお問い合わせください。無料で調査、お見積り致します。","start_url":"/","background_color":"#fff","theme_color":"#033e8e","display":"minimal-ui","icon":"static/favicon.png","icon_options":{"purpose":"any maskable"},"icons":[{"src":"static/images/icon/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"static/images/icon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"static/images/icon/icon-144x144.png","sizes":"144x144","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ca6789b1f82925fb576c8197dcb4518d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-274452088","UA-134040542-2"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
